<template>
  <q-page>
    <div class="row full-width">
      <q-table
          :columns="columns"
          :rows="store.state.partners.data"
          :rows-per-page-options="[lengthData]"
          class="full-width"
          hide-bottom
          row-key="index"
          title="Расположение организаций"
          @request="getPartners">
        <template v-slot:top-right>
          <q-btn :disabled="store.state.partners.positions.length === 0" color="accent" icon="done"
                 label="Сохранить расположение"
                 size="sm"
                 @click="savePosition"/>
        </template>
        <template v-slot:body="props">

          <q-tr :id="props.row.position" :props="props" draggable="true" @dragend="droped($event)"
                @dragover="dragover($event, props.row)" @dragstart="dragit($event, props.row)">
            <q-td class="text-center">{{ props.row.position }}</q-td>
            <q-td class="text-center">{{ props.row.account_no }}</q-td>
            <q-td class="text-left">
              <q-img :src="props.row.logo_url" fit="contain" loading="lazy" style="width: 80px; height: 80px;">
              </q-img>
            </q-td>

            <!--            <q-td class="text-center">{{ props.row.name }}</q-td>-->
            <q-td class="text-center">
              <long-text-field :maxlen="100" :value="props.row.name"/>
            </q-td>
            <!--            <q-td class="text-center">{{ props.row.rating }}</q-td>-->
            <!--            <q-td class="text-center">{{$filters.toHumanDate(props.row.date)  }}</q-td>-->
            <q-td class="text-center">
              <q-btn
                  :to="'details/'+props.row.account_no"
                  dense
                  icon="edit"
                  round
                  size="sm">
                <q-tooltip>Редактировать</q-tooltip>
              </q-btn>
              <!--              <q-btn-->
              <!--                  dense-->
              <!--                  icon="delete"-->
              <!--                  round-->
              <!--                  size="sm"-->
              <!--                  @click="deletePartner(props.row.id)">-->
              <!--                <q-tooltip>Удалить</q-tooltip>-->
              <!--              </q-btn>-->

            </q-td>
          </q-tr>

        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue';
import {PartnerApi} from '@/api/apis/partner-api.ts';
import {useStore} from 'vuex';
import LongTextField from '@/components/text/longTextField';
import {Notify} from 'quasar';
import $filters from '../../helpers/filters';

const columns = [
  {
    name: 'position',
    field: 'position',
    align: 'center',
    label: 'Позиция',
  },
  {
    name: 'account_no',
    field: 'account_no',
    align: 'center',
    label: 'Id',
  },
  {
    name: 'logo_url',
    field: 'logo_url',
    align: 'left',
    label: 'Иконка партнёра',
  },
  {
    name: 'name',
    field: 'name',
    label: 'Название партнёра',
    align: 'center',
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    sortable: false,
    align: 'left',
  },
];

export default defineComponent({
  computed: {
    $filters() {
      return $filters;
    },
  },
  components: {LongTextField},
  setup() {
    const store = useStore();
    const moveItem = ref(null);
    const loading = ref(false);
    const lengthData = ref(0);

    async function getPartners() {
      loading.value = true;
      const response = await new PartnerApi().partnerControllerGetPartners(0, 100);
      store.dispatch('updatePartners', response.data.data);
      lengthData.value = response.data.length;
      loading.value = false;
    }

    onMounted(() => getPartners());

    const dragit = (event, item) => {
      moveItem.value = item;
    };

    const dragover = (e, item) => {
      const clonePartners = [...store.state.partners.data];
      const ItemIndex = clonePartners.indexOf(moveItem.value);
      const woveItemIndex = clonePartners.indexOf(item);
      clonePartners.splice(ItemIndex, 1);
      clonePartners.splice(woveItemIndex, 0, moveItem.value);
      store.dispatch('updatePartners', clonePartners);
    };
    const droped = () => {
      store.dispatch('updatePartnersDropped', store.state.partners.data);
    };

    // const addEditRedirect = (item) => {
    //   store.dispatch('editItemUpdate', item)
    // }

    // async function deletePartner(id) {
    //   const response = await new PartnerApi().deletePartner(id)
    //   if (response.status === 202) {
    //     store.dispatch('deleteItem', id)
    //   }
    // }

    async function savePosition() {
      const response = await new PartnerApi().updatePositionPartner(store.state.partners.positions);
      // const response = await new PartnerApi().updatePositionPartner([{a:1}])
      if (response.status === 202) {
        store.dispatch('clearPartnersPositions');
        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      }
    }

    return {
      lengthData,
      // addEditRedirect,
      // deletePartner,
      savePosition,
      dragover,
      dragit,
      droped,
      getPartners,
      columns,
      store,

    };
  },
});
</script>
<style>
.q-btn {
  margin-left: 10px;
}
</style>
